.gifts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    list-style: none;
    padding: 0;
    width: 100%;
}


@media (min-width: 600px) {
    .gifts {
        grid-template-columns: 1fr 1fr ;
    }
}

@media (min-width: 768px) {
    .gifts {
        grid-template-columns: 1fr 1fr 1fr ;
    }
}

@media (min-width: 1200px) {
    .gifts {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}












/* GRID STYLES
.gifts-grid {
    grid-area: gifts-grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-content: start; 
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 1rem;
}

.gifts-grid > .gift-grid-item {
    grid-auto-flow: row;
    grid-auto-columns: 4fr 1fr 1fr 1fr;
    box-shadow: 1px 5px 10px 1px rgba(0,0,0,0.3);
    padding: 1rem;
} */
