header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.auth-list,
.auth-list ul {
  display: flex;
  flex-direction: row;
}

.auth-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.auth-list ul li {
  margin-left: .5rem;
}

.btn-loginout {
  color: white;
  background-color: hsl(33, 100%, 55%);
  padding: .5rem;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  height: 36px;
  margin-left: .5rem;
}

.btn-loginout:hover {
  background-color: hsl(33, 100%, 50%);
}