@font-face {
  font-family: "Mona-Sans";
  src: local("Mona-Sans"), url("./fonts/Mona\ Sans/Mona-Sans.ttf") format("truetype");
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 1em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  width: fit-content;
}

.success {
  color: #155724;
  background-color: #d4edda;
 }

.error {
  color: #721c24;
  background-color: #f8e1e3;
}

.warning {
  color: red;
  background-color: rgba(252, 152, 152, 0.5);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

h1, h2 {
  margin-bottom: .75rem;
}

input {
  margin-bottom: .75rem;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  max-width: 50%;
}
.modal-content > * {
  display: block;
}