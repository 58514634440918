button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 1.5rem;
    background-color: none;
    border: none;
    padding: .25rem;
    margin: .25rem
}

.success:hover {
    background-color: rgba(116, 188, 122, 0.5);
}

.warning:hover {
    background-color: rgba(210, 126, 126, 0.5);
}


.title {
    font-size: 1.5rem;
}

.brand {
    font-size: .8rem;
    font-weight: 300;
}

.purchased,
.purchased > p  {
    color: rgba(0,0,0,0.5);
}