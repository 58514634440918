.filters {
    min-width: 200px;
    display: none;   
}

.filters.filters-expanded {
    background-color: rgba(225, 225 225, 0.55);
    backdrop-filter: blur(10px);
    display: block;
    height: 75vh;
    left: 1rem;
    opacity: 1;
    position:absolute;
    min-width: 250px;
    max-width: 50%;
    border: 1px solid #ccc;
}

@media (min-width: 768px) {
    .filters {
        display: block;
    } 
}
.filters ul {
    list-style: none;
    padding-left: 0.5rem;
}

.filters input {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
}

.filters h2  {
    margin-top: 1.5rem;
}

.filters h2:first-child  {
    margin-top: 0;
}