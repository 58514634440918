
.toggleButtons {
    background-color: rgb(245, 245, 245);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.toggleGrid {
    display: none;
}

@media (min-width: 768px) {
    .toggleFilter {
        display: none;
    }
    .toggleGrid {
        display: block;
    }
}
